import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SortablejsModule } from '@dustfoundation/ngx-sortablejs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { FaviconPreviewComponent } from './components/favicon-preview/favicon-preview.component';
import { FrameComponent } from './components/frame/frame.component';
import { PlaylistControlsComponent } from './components/playlist-controls/playlist-controls.component';
import { PlaylistEditorComponent } from './components/playlist-editor/playlist-editor.component';
import { TracksEditorComponent } from './components/playlist-editor/tracks-editor/tracks-editor.component';
import { PlaylistTileComponent } from './components/playlist-tile/playlist-tile.component';
import { TracksDisplayComponent } from './components/playlist-tile/tracks-display/tracks-display.component';
import { TimeSelectorComponent } from './components/time-selector/time-selector.component';
import { TracksLegendComponent } from './components/tracks-legend/tracks-legend.component';
import { UserIdentifierModalComponent } from './components/user-identifier-modal/user-identifier-modal.component';
import { DoubleClickEditDirective } from './directives/double-click-edit.directive';
import { ShortyPipe } from './pipes/shorty.pipe';
@NgModule({
  declarations: [
    PlaylistTileComponent,
    DoubleClickEditDirective,
    FaviconPreviewComponent,
    FrameComponent,
    PlaylistControlsComponent,
    UserIdentifierModalComponent,
    TracksDisplayComponent,
    PlaylistEditorComponent,
    ShortyPipe,
    TimeSelectorComponent,
    TracksEditorComponent,
    TracksLegendComponent,
  ],
  exports: [PlaylistTileComponent, FrameComponent, PlaylistControlsComponent, PlaylistEditorComponent, ShortyPipe, TracksLegendComponent],
  imports: [
    NgxPopperjsModule.forRoot({ placement: NgxPopperjsPlacements.AUTO, trigger: NgxPopperjsTriggers.hover }),
    CommonModule,
    SortablejsModule,
    FormsModule,
    DragDropModule,
    BsDropdownModule.forRoot(),
  ]
}) export class SharedModule { }