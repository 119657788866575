import { Component, Input } from '@angular/core';
import { Playlist, Track } from '@reflact/interactionsuite';
import { PlayerService } from '../../services/player.service';
import { PlaylistService } from '../../services/playlist.service';

@Component({
  selector: 'app-playlist-controls',
  templateUrl: './playlist-controls.component.html',
  styleUrls: ['./playlist-controls.component.scss']
})
export class PlaylistControlsComponent {
  @Input() public currentTrack?: Track;
  @Input() public playlist?: Playlist;
  @Input() public playlistStatus?: Playlist['playState']['status'];

  constructor(public playlistService: PlaylistService,
    public playerService: PlayerService) {
  }



  public getCurrentTrackIndex(): number {
    if (!this.currentTrack) return -1;
    if (!this.playlist) return -1;
    let index = this.playlist.tracks.findIndex((t: Track) => t.id == this.currentTrack?.id);
    return index;

  }


  public isLastTrack() {
    if (!this.currentTrack) return true;
    if (!this.playlist) return true;
    let index = this.getCurrentTrackIndex();
    if (index == this.playlist?.tracks.length - 1) return true;
    return false;


  }
  public isFirstTrack() {
    if (!this.currentTrack) return true;
    let index = this.getCurrentTrackIndex();
    if (index == 0) return true;
    return false;
  }


  public back() {
    if (!this.playlist) return;
    let index = this.getCurrentTrackIndex();
    if (this.isFirstTrack()) return;
    let nextIndex = --index;
    let nextTrack = this.playlist.tracks[nextIndex];
    this.playerService.playTrack(this.playlist._id, nextTrack, "playing");

  }

  public next() {
    if (!this.playlist) return;
    let index = this.getCurrentTrackIndex();
    if (this.isLastTrack()) return;
    let nextIndex = ++index;
    let nextTrack = this.playlist.tracks[nextIndex];
    this.playerService.playTrack(this.playlist._id, nextTrack, "playing");
  }


  public setPlayStateStatus(status: "playing" | "paused") {
    if (!this.playlist) return;
    this.playerService.playTrack(this.playlist._id, this.playlist.tracks[this.getCurrentTrackIndex()], status);

  }

}
