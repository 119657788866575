<div
  id="viewer"
  [ngStyle]="{'width': viewMode == 'admin'? '100%' : '100vw', 'height': viewMode == 'admin'? '100%' : '100vh'}"
>
  <app-frame
    *ngIf="currentTrack"
    [track]="currentTrack"
    [playlistId]="playlistId"
  ></app-frame>

  <div
    id="isConfidential"
    class="overlay bg-warning"
    *ngIf="this.currentTrack?.isConfidential && this.showIsConfidential"
  >
    <i class="ri ri-error-warning-fill"></i>
    {{this.currentTrack?.confidentialText}}
    <div
      class="btn btn-warning ms-3"
      (click)="this.hideIsConfidential()"
      i18n="@@isConfidentialHideMessage"
    ><i class="ri ri-close-line"></i>Hide message</div>
  </div>

  <div
    id="remainingTime"
    class="overlay bg-primary"
    *ngIf="this.currentTrack?.showTimer && this.remainingTime != undefined"
  >
    {{remainingTime}}
  </div>

  <div
    *ngIf="timeProgressPercentage && this.currentTrack?.showTimer && this.remainingTime != undefined"
    class="overlay"
    id="progressbar"
  >
    <progressbar
      [value]="timeProgressPercentage"
      [type]="timeProgressPercentage < 20 ? timeProgressPercentage < 10 ? 'danger' : 'warning' : undefined"
      style="max-height: 5px;"
    ></progressbar>
  </div>

  <div class="attendanceControlOverlay overlay">
    <div
      #attendanceBtn
      class="bubble pt-4"
    >
      <div class="row">
        <div class="col-3 ms-3 pe-0"><i class="ri ri-open-arm-line ri-3x"></i></div>
        <div class="col-7">
          <div class="row mt-3 text-start">
            <div
              class="col-12"
              i18n="@@attendanceControlAreYouThere"
            >Are you still here?</div>
            <div class="col-12 text-primary">
              <i class="ri ri-check-line me-1"></i>
              <a
                (click)="submitAttendance()"
                i18n="@@attendanceControlIamHere"
              >Of course!</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>