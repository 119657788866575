<ng-container *ngIf="this.playlist">
  <div class="card shadow border-0">
    <div class="bg-lightgray rounded-top">
      <app-tracks-display [tracks]="this.playlist.tracks"></app-tracks-display>
    </div>

    <div class="p-3">
      <b>{{this.playlist.name}}</b>
      <div
        class="row mt-2"
        *ngIf="!this.selectScenarioMode"
      >
        <div class="col ms-n2">
          <div
            class="btn btn-white btn-sm"
            [popper]="copied"
            [popperTrigger]="popperTriggerClick"
            (click)="copyToClipBoard('viewer',$event)"
            (popperOnShown)="popperShow($event)"
            i18n="@@playlistTileEmbed"
          ><i class="ri-file-copy-2-fill"></i>&nbsp;Embed</div>
        </div>
        <div class="col-auto px-0">
          <div
            class="btn btn-white btn-sm"
            [popper]="copied"
            [popperTrigger]="popperTriggerClick"
            (click)="copyToClipBoard('dj', $event)"
            (popperOnShown)="popperShow($event)"
            i18n="@@playlistTileAdmin"
          ><i class="ri-file-copy-2-fill"></i>&nbsp;Admin</div>
        </div>
        <div class="col text-end me-n2">
          <div
            class="btn btn-white btn-sm"
            (click)="this.edit.emit(playlist)"
            i18n="@@edit"
          ><i class="ri-pencil-fill"></i>&nbsp;Edit</div>
        </div>
      </div>
      <div
        class="row mt-2"
        *ngIf="this.selectScenarioMode"
      >
        <div class="col-auto px-0">
          <div
            class="btn btn-white btn-sm"
            (click)="this.selected.emit(playlist)"
            i18n="@@playlistTileSelectScenario"
          ><i class="ri-play-fill"></i>&nbsp;Select scenario</div>
        </div>
      </div>
    </div>
  </div>

  <popper-content #copied>
    <span
      class="text-nowrap"
      i18n="@@playlistTileCopiedToClipboard"
    >
      copied to clipboard
    </span>
  </popper-content>


</ng-container>