<div class="row align-items-center mx-0">
  <div class="col p-0 m-0"></div>
  <div class="col-auto">
    <div
      class="btn-small mr-3 d-flex justify-content-center align-items-center"
      (click)="back()"
      [ngClass]="{'btn-disabled': isFirstTrack()}"
    >
      <i class="icon-btn ri-arrow-left-s-line"></i>
    </div>
  </div>
  <div class="col-auto">

    <div
      class="btn-small btn-big mr-3 d-flex justify-content-center align-items-center"
      *ngIf="this.playlistStatus == 'playing'"
      (click)="this.setPlayStateStatus('paused')"
    >
      <i class="icon-btn ri-pause-fill"></i>
    </div>

    <div
      class="btn-small btn-big mr-3 d-flex justify-content-center align-items-center"
      *ngIf="this.playlistStatus == 'paused'"
      (click)="this.setPlayStateStatus('playing')"
    >
      <i class="icon-btn ri-play-fill"></i>
    </div>
  </div>
  <div class="col-auto">
    <div
      class="btn-small mr-3 d-flex justify-content-center align-items-center"
      (click)="next()"
      [ngClass]="{'btn-disabled': isLastTrack()}"
    >
      <i class="icon-btn ri-arrow-right-s-line"></i>
    </div>
  </div>
  <div class="col p-0 m-0"></div>
</div>