import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private loginService: LoginService, private router: Router) { }
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    if (this.loginService.loggedInUser) {
      return true;
    }
    const loginResult = await this.loginService.getTokenAndLogin();
    if (loginResult.status === 'ok') {
      return true;
    }
    let queryParams = undefined;
    let requestedView = undefined;
    if (route.routeConfig?.path != null) {
      requestedView = route.routeConfig.path;
      queryParams = { linkTo: requestedView };
    }
    this.router.navigate(['/', 'login'], { queryParams });
    return false;
  }

}
