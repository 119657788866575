import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SubmitAttendanceRequest, SubmitAttendanceResponse } from '@reflact/interactionsuite';
import { ObjectID, deserialize, serialize } from 'bson';
import { firstValueFrom } from 'rxjs';

export type AttendanceObject = { id: ObjectID, until: number, token: string };

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {

  constructor(private http: HttpClient) { }
  public async submitAttendance(currentAttendanceObject: AttendanceObject) {
    const url = "/api/bson/submitattendance";
    const body: SubmitAttendanceRequest = {
      attendanceId: currentAttendanceObject.id,
      submitToken: currentAttendanceObject.token
    }
    const result = await firstValueFrom(this.http.post(url, this.transformToBson(body), { responseType: 'arraybuffer' }));
    return deserialize(result) as SubmitAttendanceResponse;
  }
  private transformToBson(body: any) {
    const buffer = serialize(body)
    return new Blob([buffer]);
  }
}
