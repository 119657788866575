import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Playlist } from '@reflact/interactionsuite';
import { OverlayService } from '@reflact/ngx-layout';
import { NgxPopperjsDirective, NgxPopperjsTriggers } from 'ngx-popperjs';
import { PlaylistService } from '../../services/playlist.service';

@Component({
  selector: 'app-playlist-tile',
  templateUrl: './playlist-tile.component.html',
  styleUrls: ['./playlist-tile.component.scss']
})

export class PlaylistTileComponent {
  public popperTriggerClick = NgxPopperjsTriggers.click;
  @Output() public edit: EventEmitter<Playlist> = new EventEmitter<Playlist>();
  @Output() public selected: EventEmitter<Playlist> = new EventEmitter<Playlist>();
  @Input() public playlist?: Playlist;
  @Input() public selectScenarioMode: boolean = false;
  constructor(public playlistService: PlaylistService, public overlayService: OverlayService) { }





  copyToClipBoard(type: "viewer" | "dj", event: MouseEvent) {

    if (!this.playlist) return;
    let clipBoardMsg = "";
    if (type == "viewer") {
      clipBoardMsg = this.playlistService.getViewerUrl(this.playlist._id)
    }
    if (type == "dj") {
      clipBoardMsg = this.playlistService.getDjUrl(this.playlist._id)
    }
    if (event.metaKey) {
      window.open(clipBoardMsg, '_blank');
      return;
    }
    this.copyMessage(clipBoardMsg);
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  popperShow(event: NgxPopperjsDirective) {
    setTimeout(() => {
      event.hide();
    }, 1000);
  }

}
