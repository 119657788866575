import { Injectable } from '@angular/core';
import { IasClientToServerEvents, IasServerToClientEvents } from '@reflact/interactionsuite';
import { BehaviorSubject, Subject } from 'rxjs';
import { Socket, io } from 'socket.io-client';
import { LoginService } from './login.service';

export type IasSocket = Socket<IasServerToClientEvents, IasClientToServerEvents>
@Injectable({
  providedIn: 'root'
})
export class SocketService {
  public error$: Subject<any> = new Subject();
  public static wasDisconnected: boolean = false;
  public static registered: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private static socket_: IasSocket;

  constructor(private loginService: LoginService) { }

  public get socket(): Promise<IasSocket> {
    return new Promise((resolve, reject) => {
      if (!SocketService.socket_) {
        SocketService.socket_ = io('//');
        SocketService.socket_.on('connect', async () => {
          if (SocketService.wasDisconnected) {
            SocketService.wasDisconnected = false;
          }
          let token = undefined;
          let shareToken = undefined;
          let isGuest = true;
          if (this.loginService.token != undefined) {
            token = this.loginService.token;
            isGuest = false;
          } else {
            const result = await this.loginService.getTokenAndLogin();
            if (result.status === 'ok') {
              token = this.loginService.token;
              isGuest = false;
            }
          }
          if (token == undefined && this.loginService.shareToken != undefined) {
            shareToken = this.loginService.shareToken;
          }
          SocketService.socket_.emit('registerSocket', { token, isGuest, shareToken }, (result) => {
            if (result.status === 'ok') {
              console.log('registered')
              SocketService.registered.next(true);
              return resolve(SocketService.socket_);
            }
            reject('not registered')
          });
        })
        SocketService.socket_.on('disconnect', () => {
          SocketService.wasDisconnected = true;
          SocketService.registered.next(false);
        })
      } else {
        resolve(SocketService.socket_);
      }
    });
  }
}
