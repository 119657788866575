import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AttendanceInfoRequest, CreatePlaylistRequest, CreatePlaylistResponse, DeletePlaylistRequest, DeletePlaylistResponse, GetPlaylistRequest, GetPlaylistResponse, GetPlaylistsResponse, GetShareTokenRequest, GetShareTokenResponse, LinkPreviewResponse, Playlist, Track, UpdatePlaylistRequest, UpdatePlaylistResponse } from '@reflact/interactionsuite';
import { ObjectId, deserialize, serialize } from 'bson';
import { saveAs } from 'file-saver';
import { firstValueFrom } from 'rxjs';
import { LoginService } from './login.service';
import { ToastrService } from 'ngx-toastr';
import { th } from 'date-fns/locale';
@Injectable({
  providedIn: 'root'
})
export class PlaylistService {
  constructor(private http: HttpClient, private loginService: LoginService, private toastrService: ToastrService) { }

  public getDjUrl(playlistId: ObjectId): string {
    let path = "/#/player/" + playlistId;
    return window.location.origin + "" + path;
  }

  public getViewerUrl(playlistId: ObjectId): string {
    let path = "/#/viewer/" + playlistId;
    return window.location.origin + "" + path;
  }

  public getIframeCode(playlistId: ObjectId): string {
    return "<iframe src='" + this.getViewerUrl(playlistId) + "' width='300' height='300'>";
  }

  public async getShareToken(playlistId: ObjectId) {
    const url = "/api/bson/shareToken/get/";
    const body: GetShareTokenRequest = {
      playlistId
    }
    const result = await firstValueFrom(this.http.post(url, this.transformToBson(body), { responseType: 'arraybuffer' }));
    return deserialize(result) as GetShareTokenResponse;
  }

  public async getPlaylist(playlistId: ObjectId) {
    const url = "/api/bson/playlist/get/";
    const body: GetPlaylistRequest = {
      playlistId
    }
    const result = await firstValueFrom(this.http.post(url, this.transformToBson(body), { responseType: 'arraybuffer' }));
    return deserialize(result) as GetPlaylistResponse;
  }

  public async getPlaylistInfo(playlistId: ObjectId) {
    const url = "/api/bson/publicplaylistinfo";
    const body: GetPlaylistRequest = {
      playlistId
    }
    const result = await firstValueFrom(this.http.post(url, this.transformToBson(body), { responseType: 'arraybuffer' }));
    return deserialize(result) as any;
  }

  public async listPlaylists() {
    const url = "/api/bson/playlist/list/";
    const result = await firstValueFrom(this.http.post(url, this.transformToBson({}), { responseType: 'arraybuffer' }));
    return deserialize(result) as GetPlaylistsResponse;
  }

  public async updatePlaylist(playlist: Partial<Playlist> & { _id: ObjectId }) {
    // delete playState so it can never be updated by this call

    delete playlist.playState;
    const url = "/api/bson/playlist/update/";
    const body: UpdatePlaylistRequest = {
      playlist
    }
    const result = await firstValueFrom(this.http.post(url, this.transformToBson(body), { responseType: 'arraybuffer' }));
    this.toastrService.success($localize`:@@globalSaved:Saved!`);
    return deserialize(result) as UpdatePlaylistResponse;
  }

  public async createPlaylist(playlist: Omit<Playlist, "ownerId" | "playState">) {
    const url = "/api/bson/playlist/create/";
    const body: CreatePlaylistRequest = {
      playlist
    }
    const result = await firstValueFrom(this.http.post(url, this.transformToBson(body), { responseType: 'arraybuffer' }));
    return deserialize(result) as CreatePlaylistResponse;
  }

  public async deletePlaylist(playlistId: ObjectId) {
    const url = "/api/bson/playlist/delete/";
    const body: DeletePlaylistRequest = {
      _id: playlistId
    }
    const result = await firstValueFrom(this.http.post(url, this.transformToBson(body), { responseType: 'arraybuffer' }));
    return deserialize(result) as DeletePlaylistResponse;
  }

  public async attendanceExport(playlistId: ObjectId) {
    const url = "/api/bson/attendanceexport";
    const body: AttendanceInfoRequest = {
      playlistId
    }
    const result = await firstValueFrom(this.http.post(url, this.transformToBson(body), { responseType: 'arraybuffer' }));
    const xlsxBlob = new Blob([result], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    return saveAs(xlsxBlob, "attendanceExport.xlsx");
  }

  public async loadUrl(inputUrl: string) {
    const url = "/api/urlpreview/" + encodeURIComponent(inputUrl);
    const result = await firstValueFrom(this.http.get<LinkPreviewResponse>(url));
    return result;
  }

  public getImageByTrack(track: Track) {
    if (track.data.linkinfo?.info?.links?.image && track.data.linkinfo?.info?.links.image[0]?.href) {
      let url = "/api/previewfile/" + encodeURIComponent(track.data.linkinfo.info.links.image[0].href);
      if (this.loginService.token) {
        url += '?access_token=' + this.loginService.token;
      } else if (this.loginService.shareToken) {
        url += '?shareToken=' + this.loginService.shareToken;
      }
      return url;
    }
    return this.getFaviconByTrack(track);
  }

  public getFaviconByTrack(track: Track) {
    if (track.data.linkinfo?.info?.links?.icon && track.data.linkinfo?.info?.links?.icon[0]?.href) {
      const faviconUrl = track.data.linkinfo?.info?.links?.icon[0]?.href;
      let url = "/api/previewfile/" + encodeURIComponent(faviconUrl)
      if (this.loginService.token) {
        url += '?access_token=' + this.loginService.token;
      } else if (this.loginService.shareToken) {
        url += '?shareToken=' + this.loginService.shareToken;
      }
      return url;
    }
    return undefined;
  }

  public transformToBson(body: any) {
    const buffer = serialize(body)
    return new Blob([buffer]);
  }
}