import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Language, LoginByTokenRequest, LoginRequest, LoginResponse, LostPwRequest, LostPwResponse, RegisterRequest, UserTokenContents, ValidateShareTokenRequest, ValidateShareTokenResponse } from '@reflact/interactionsuite';
import { deserialize, serialize } from 'bson';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public loggedInUser$ = new BehaviorSubject<UserTokenContents | undefined>(undefined);
  public loggedInUser: UserTokenContents | undefined = undefined;
  public token: string | undefined = undefined
  public shareToken: string | undefined = undefined;

  constructor(public http: HttpClient) {
  }
  public async getTokenAndLogin(): Promise<LoginResponse> {
    try {
      const token = localStorage.getItem('interactionSuiteToken');
      if (token == null) {
        return { status: 'forbidden' };
      }
      return await this.loginByToken(token + "")
    } catch (error) {
      return { status: 'forbidden' };
    }
  }
  public async loginByToken(token: string) {
    const url = '/api/bson/loginByToken'
    const body: LoginByTokenRequest = {
      token,
    }
    const result = await firstValueFrom(this.http.post(url, this.transformToBson(body), { responseType: 'arraybuffer' }));
    const loginResult = deserialize(result) as LoginResponse;
    if (loginResult.status === 'ok') {
      this.afterLogin(loginResult);
    }
    return loginResult;
  }
  public async isShareTokenValid(shareToken: string) {
    const url = '/api/bson/shareToken/validate'
    const body: ValidateShareTokenRequest = {
      shareToken,
    }
    const result = await firstValueFrom(this.http.post(url, this.transformToBson(body), { responseType: 'arraybuffer' }));
    const loginResult = deserialize(result) as ValidateShareTokenResponse;
    if (loginResult.status === 'ok') {
      this.afterLogin(loginResult);
    }
    return loginResult;
  }
  public async login(email: string, password: string) {
    const url = '/api/bson/login'
    const body: LoginRequest = {
      email,
      password
    }
    const result = await firstValueFrom(this.http.post(url, this.transformToBson(body), { responseType: 'arraybuffer' }));
    const loginResult = deserialize(result) as LoginResponse;
    if (loginResult.status === 'ok') {
      this.afterLogin(loginResult);
    }
    return loginResult;
  }
  public async register(email: string, name: string, language: Language, password: string) {
    const url = '/api/bson/register'
    const body: RegisterRequest = {
      email,
      name,
      language,
      password
    }
    const result = await firstValueFrom(this.http.post(url, this.transformToBson(body), { responseType: 'arraybuffer' }));
    const loginResult = deserialize(result) as LoginResponse;
    console.log('result', loginResult)
    if (loginResult.status === 'ok') {
      this.afterLogin(loginResult);
    }
    return loginResult;
  }

  public async resetPassword(email: string) {
    const url = '/api/bson/lostpw'
    const body: LostPwRequest = {
      email
    }
    const result = await firstValueFrom(this.http.post(url, this.transformToBson(body), { responseType: 'arraybuffer' }));
    return deserialize(result) as LostPwResponse;
  }

  private afterLogin(loginResult: LoginResponse) {
    if (loginResult.token) {
      this.token = loginResult.token;
      try {
        localStorage.setItem('interactionSuiteToken', "" + loginResult.token);
      } catch (error) {
        console.log('Cannot access localStorage');
      }
    }
    this.loggedInUser = loginResult.user;
    this.loggedInUser$.next(loginResult.user)
  }

  public getLogoutFunction() {
    return () => {
      this.logout();
      window.location.reload()
    }
  }
  public logout() {
    try {
      localStorage.removeItem('interactionSuiteToken');
    } catch (error) {
      console.log('Cannot access localStorage');
    }
    this.token = undefined;
    this.loggedInUser = undefined;
    this.loggedInUser$.next(undefined);
  }
  public getAuthorizationToken(): string | undefined {
    if (this.token == undefined) {
      return undefined;
    }
    return 'Bearer ' + this.token;
  }
  public transformToBson(body: any) {
    const buffer = serialize(body)
    return new Blob([buffer]);
  }
}
