import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LinkPreviewResponse, Playlist, Track } from '@reflact/interactionsuite';
import { UUID } from 'bson';
import { SortableOptions } from 'sortablejs';
import { PlayerService } from '../../services/player.service';
import { PlaylistService } from '../../services/playlist.service';

@Component({
  selector: 'app-tracks-legend',
  templateUrl: './tracks-legend.component.html',
  styleUrls: ['./tracks-legend.component.scss']
})
export class TracksLegendComponent implements OnInit {
  @Input({ required: true }) public mode!: "legend" | "edit";
  @Input({ required: true }) public playlist!: Playlist;
  @Input() maxHeight?: number;
  @Input() public selectedTrack: Track | undefined = undefined;
  @Output() public moveTrack = new EventEmitter<Track>()
  @Output() public selectedTrackChange = new EventEmitter<Track | undefined>()
  public sortableOptions: SortableOptions = {};
  public editSelected: boolean = false;
  public loading = false;
  public currentUrl = "";
  public calcnum = 40;
  constructor(public playlistService: PlaylistService, private playerService: PlayerService) {
  }

  async addButtonClicked() {
    this.loading = true;
    const result = await this.playlistService.loadUrl(this.currentUrl)
    this.loading = false;
    console.log('lastURLResult', result);
    if (result.status === 'ok') {
      this.addTrack(result)
    }
  }

  addTrack(previewResponse: LinkPreviewResponse) {
    if (!this.playlist) return;
    let t: Track = {
      id: (new UUID()) + "",
      type: "website",
      data: {
        name: previewResponse.info.meta.title ? previewResponse.info.meta.title.replace("https://", "") : previewResponse.info.url,
        url: previewResponse.info.url,
        linkinfo: previewResponse
      },
      timerEnabled: false,
      timerSeconds: 600,
      showTimer: false,
      ignoreAttendanceControl: false,
      isConfidential: false,
      timerAutoNav: false,
      confidentialText: $localize`:@@tracksLegendConfidentialContentText:Confidential content`
    }
    this.playlist.tracks.push(t);
    this.playlistService.updatePlaylist(this.playlist);
    this.currentUrl = "";
    if (!this.selectedTrack) this.selectTrack(this.playlist.tracks[this.playlist.tracks.length - 1])


  }

  ngOnInit(): void {
    this.sortableOptions = {
      disabled: this.mode != 'edit',
      handle: '.handle',
      onUpdate: this.tracksSorted.bind(this)
    }
  }

  public selectTrack(track: Track | undefined) {
    this.editSelected = false;
    this.selectedTrack = track;
    this.selectedTrackChange.emit(this.selectedTrack)
    if (track) {
      this.playerService.playTrack(this.playlist?._id, track, "playing");
    }
  }



  calculateTracksHeight(): number {
    let height = this.calcnum * this.playlist.tracks.length;
    if (this.mode == "edit") {
      height += 12;
      height = Math.min(365, height);
    } else {
      height -= this.calcnum
    }
    if (this.maxHeight) {
      return Math.min(this.maxHeight)
    }
    return height;
  }

  tracksSorted() {
    if (this.playlist == null) {
      return;
    }
    this.playlistService.updatePlaylist(this.playlist)
  }

  async duplicateThisFlow(track: Track) {
    let t: Track = JSON.parse(JSON.stringify(track));
    t.id = (new UUID()) + "";
    this.playlist?.tracks.push(t);
    await this.playlistService.updatePlaylist(this.playlist);
  }

  removeTrackFromPlayList(indexId: number) {
    if (!this.playlist) return;
    if (this.selectedTrack == this.playlist?.tracks.splice(indexId, 1)[0]) {
      if (this.playlist.tracks.length > 0) {
        this.selectTrack(this.playlist.tracks[0])
      } else {
        this.selectTrack(undefined)
      }
    }
    this.playlistService.updatePlaylist(this.playlist);

  }
}
