import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Language, LoginResponse, MsgStatus } from '@reflact/interactionsuite';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  public name?: string;
  public language: Language = 'de';
  public email?: string;
  public password?: string;
  public password2?: string;
  public message = '';

  constructor(private loginService: LoginService, private router: Router) { }

  public async register() {
    if (this.name && this.language && this.email && this.password && this.password === this.password2) {
      const result = await this.loginService.register(this.email, this.name, this.language, this.password);
      if (result.status === 'ok') {
        this.router.navigate(['/', 'dashboard']);
      } else {
        console.log(result);
        this.setMessage(result);
      }
    } else if (this.password !== this.password2) {
      this.message = 'Passwords not matching';
    }
  }

  private setMessage(response: LoginResponse) {
    switch (response.message) {
      case 'alreadyregistered':
        this.message = $localize`:@@registerAlreadyRegistered:Already registered`;
        break;
      case 'invalid email':
        this.message = $localize`:@@registerInvalidEMail:Invalid e-mail`;
        break;
      default:
        if (response.message && response.message.indexOf('validPW') >= 0) {
          this.message = $localize`:@@registerPasswordLength:Password must be 8 chars or longer`;
        }
        break;
    }
  }
}
