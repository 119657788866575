import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AiService {
  constructor(private http: HttpClient) { }

  public async createScenario(theme: string, interactionCount: number): Promise<[]> {
    let res = await firstValueFrom(this.http.post<string>('/ai/scenario-maker', { theme, interactionCount }));
    res = res.trim();
    return JSON.parse(res).interactions;
  }
}