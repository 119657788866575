import { Component } from '@angular/core';

@Component({
  selector: 'app-paused-scenario',
  templateUrl: './paused-scenario.component.html',
  styleUrls: ['./paused-scenario.component.scss']
})
export class PausedScenarioComponent {

}
