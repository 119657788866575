import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private loginService: LoginService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const authToken = this.loginService.getAuthorizationToken();

    const shareToken = this.loginService.shareToken;
    if (shareToken != undefined && (request.url.includes('/playlist/get') || request.url.includes('/api/previewfile/'))) {
      const shareTokenReq = request.clone({ url: request.url + "?shareToken=" + shareToken });
      // send cloned request with header to the next handler.
      return next.handle(shareTokenReq);
    }

    if (authToken == undefined) {

      // wenn kein authToken da ist einfach den alten request nexten
      return next.handle(request);
    }
    const authReq = request.clone({ setHeaders: { Authorization: authToken } });
    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}
