<div
  *ngIf="loginService.shareToken != undefined"
  class="btn btn-sm btn-primary m-2 switch-btn position-absolute"
  (click)="emitSwitchScenario()"
  i18n="@@playerSwitchScenario"
>
  Switch scenario
</div>
<div
  class="position-absolute"
  style="top: calc(50vh - 14px);right: 0px; z-index:1000000"
>
  <div
    class="btn btn-sm btn-danger border-end-0 fw-bold"
    (click)="this.openControls = !this.openControls"
  ><i
      class="ri-arrow-right-s-line"
      *ngIf="this.openControls"
    ></i><i
      class="ri-arrow-left-s-line"
      *ngIf="!this.openControls"
    ></i></div>
</div>
<div class="row align-items-center m-0 p-0">
  <div
    class="col"
    [ngClass]="{'p-5': openControls,'m-0 p-0': !openControls}"
  >
    <div
      class="row"
      *ngIf="openControls"
    >
      <div class="col-auto">
        <img
          src="assets/logo_grey_w_text.svg"
          width="250"
          class="mb-3"
          alt="logo"
        >
      </div>
      <div class="col ms-4">
        <h3>{{playlist.name}}</h3>
        <p
          class="fw-bold"
          [popper]="link"
          [popperAppendTo]="'body'"
          [popperPlacement]="popperPlacement"
          *ngIf="this.selectedTrack?.data?.linkinfo?.info?.meta?.title"
        >"{{this.selectedTrack!.data!.linkinfo!.info.meta.title|shorty: 40}}"</p>
        <popper-content #link>
          <span style="word-break: break-all;">
            {{this.selectedTrack!.data!.linkinfo!.info.meta.title}}
          </span>
        </popper-content>
      </div>
    </div>

    <div
      class="
            shadow"
      [ngStyle]="{'height': openControls ? 'calc(100vh - 35px - 75px - 6rem - 1rem)' : '100vh'}"
    >
      <app-viewer [viewMode]="'admin'"></app-viewer>
    </div>
    <div
      class="fw-bold ms-5 mt-3"
      *ngIf="openControls"
    >
      <p
        class="d-inline-block"
        i18n="@@player11ParticipantView"
      >1:1 Participant view</p>
      <div
        class="btn btn-white btn-sm"
        [popper]="copied"
        [popperTrigger]="popperTriggerClick"
        (click)="copyToClipBoard('viewer',$event)"
        (popperOnShown)="popperShow($event)"
      ><i class="ri-file-copy-2-fill"></i>&nbsp;Embed</div>
      <popper-content #copied>
        <span class="text-nowrap">
          copied to clipboard
        </span>
      </popper-content>
    </div>
  </div>
  <div
    class="col-auto d-none"
    style="height: calc(100vh - 6rem);"
    [ngClass]="{'is-open': openControls,'p-5': openControls,'m-0 p-0': !openControls}"
  >
    <div
      style="width: 0px;"
      id="legendBox"
      (window:resize)="calculateMaxHeightForLegend()"
      style="max-height: calc(100vh - 35px - 75px - 6rem - 1rem); overflow: scroll; min-width: 300px;"
    >
      <app-tracks-legend
        [maxHeight]="maxHeightForLegend"
        [mode]="'legend'"
        [playlist]="playlist"
        [(selectedTrack)]="this.selectedTrack"
      ></app-tracks-legend>
    </div>
    <div
      style="background-color: #ECF2F7; z-index: 300;"
      class="position-relative"
    >
      <app-playlist-controls
        [playlist]="this.playlist"
        [currentTrack]="this.currentTrack"
        [playlistStatus]="playlistStatus"
      ></app-playlist-controls>
    </div>
    <div>
    </div>
  </div>
</div>