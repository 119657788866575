import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Track } from '@reflact/interactionsuite';

@Component({
  selector: 'app-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss'],
})
export class FrameComponent implements OnChanges {
  @Input() track?: Track;
  @Input() playlistId?: string;

  public frameSrc?: SafeResourceUrl;
  constructor(private dom: DomSanitizer) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['track'] != null &&
      (changes['track'].isFirstChange() || changes['track'].previousValue != changes['track'].currentValue)) {
      const track: Track = changes['track'].currentValue;
      if (this.playlistId != null) {
        if (track.id == "pauseTrack") {
          this.frameSrc = this.dom.bypassSecurityTrustResourceUrl(track.data.url)
        } else {
          this.frameSrc = this.dom.bypassSecurityTrustResourceUrl(`${window.location.origin}/api/trackrenderer/${this.playlistId}/${track.id}`)
        }
      }
    }

    if (changes['playlistId'] != null &&
      (changes['playlistId'].isFirstChange()
        || changes['playlistId'].previousValue != changes['playlistId'].currentValue)) {
      const playlistId: string = changes['playlistId'].currentValue;
      if (this.track != null) {
        if (this.track.id == "pauseTrack") {
          this.frameSrc = this.dom.bypassSecurityTrustResourceUrl(this.track.data.url)
        } else {
          this.frameSrc = this.dom.bypassSecurityTrustResourceUrl(`${window.location.origin}/api/trackrenderer/${playlistId}/${this.track.id}`)
        }
      }
    }
  }

  public reloadPage() {
    window.location.reload()
  }
}
