import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Playlist, SelectedPlaylistForSharing, Track } from '@reflact/interactionsuite';
import { OverlayAsideComponent, OverlayService } from '@reflact/ngx-layout';
import { INavData } from '@reflact/ngx-layout/lib/body-layout/sidebar-nav/sidebar-nav.component';
import { RagDatasupplyFrontendService, RagDatasupplyLocalBackendService } from '@reflact/rag-datasupply';
import { ObjectId, UUID } from 'bson';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxPopperjsDirective, NgxPopperjsTriggers } from 'ngx-popperjs';
import { LoginService } from 'src/app/shared/services/login.service';
import { PlaylistService } from 'src/app/shared/services/playlist.service';
import { AiModalComponent } from './ai-modal/ai-modal.component';


@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  public popperTriggerClick = NgxPopperjsTriggers.click;
  @ViewChild("overlay") overlay!: OverlayAsideComponent;
  private aiModal?: BsModalRef;
  public playlists: Playlist[] = [];
  public selectScenarioMode: boolean = false;
  public editName: boolean = false;
  public navItems: INavData[] = [
    {
      name: "Dashboard",
      icon: "ri-chat-3-line",
      url: "/dashboard"
    }
  ];
  public trackToMove: Track | undefined = undefined;
  public selectedToDuplicate: ObjectId[] = [];


  constructor(
    public frontendService: RagDatasupplyFrontendService<Playlist>,
    public playlistService: PlaylistService,
    private backendService: RagDatasupplyLocalBackendService<Playlist>,
    public loginService: LoginService,
    public overlayService: OverlayService,
    private modalService: BsModalService,
    private aRoute: ActivatedRoute
  ) {
    if (this.aRoute.snapshot.data['selectScenario']) {
      this.selectScenarioMode = this.aRoute.snapshot.data['selectScenario']
    }
    this.loadPlaylists();
  }

  public filterScenarios(search: string) {
    this.frontendService.fromArray(this.playlists.filter(p => p.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())))
  }

  private async loadPlaylists() {
    const response = await this.playlistService.listPlaylists();
    if (response.status === 'ok') {
      this.playlists = response.playlists;
      this.frontendService.fromArray(this.playlists);
      if (this.overlayService.get("selected")) {
        this.overlay.setSelected(this.playlists.find(p => p._id.toString() == this.overlayService.get("selected")))
      }
    }
  }

  async createPlaylist() {
    const response = await this.playlistService.createPlaylist({
      _id: new ObjectId(),
      modifiedDate: new Date(),
      name: $localize`:@@dashboardNewScenario:new Scenario`,
      tracks: [],
      collectUserIdentifier: false,
      attendanceControlEnabled: false,
      attendanceControlMinutes: 5
    });
    if (response.status === 'ok') {
      this.playlists.unshift(response.playlist)
      this.frontendService.fromArray(this.playlists);
    }
  }

  async onTrackAdded(playlist: Playlist) {
    await this.playlistService.updatePlaylist(playlist);
  }

  async deletePlaylist(playlist: Playlist) {
    const response = await this.playlistService.deletePlaylist(playlist._id);
    if (response.status === 'ok') {
      const index = this.playlists.findIndex(pl => pl._id.equals(response.playlist._id));
      if (index > -1) {
        this.playlists.splice(index, 1);
        this.frontendService.fromArray(this.playlists);
      }
    }
  }

  public createAiPlaylist(e: Event) {
    e.stopPropagation();
    this.aiModal = this.modalService.show(AiModalComponent);
    this.aiModal.onHide?.subscribe(onHide => {
      this.loadPlaylists();
    });
  }
  public async emitSelectedPlaylistToParentWindow(playlist: Playlist) {
    if (window.parent) {
      const response = await this.playlistService.getShareToken(playlist._id);
      const sharedPlaylist: SelectedPlaylistForSharing = {
        shareToken: response.shareToken,
        _id: playlist._id + "",
        name: playlist.name
      }
      window.parent.postMessage(sharedPlaylist, '*');
    }
  }

  copyIframeToClipboard() {
    if (!this.overlay.selectedObject) return;
    this.copyMessage(this.playlistService.getIframeCode(this.overlay.selectedObject._id));
  }

  copyToClipBoard(playlist: Playlist, type: "viewer" | "dj", event: MouseEvent) {
    if (!playlist) return;
    let clipBoardMsg = "";
    if (type == "viewer") {
      clipBoardMsg = this.playlistService.getViewerUrl(playlist._id)
    }
    if (type == "dj") {
      clipBoardMsg = this.playlistService.getDjUrl(playlist._id)
    }
    if (event.metaKey) {
      window.open(clipBoardMsg, '_blank');
      return;
    }
    this.copyMessage(clipBoardMsg);
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }


  async duplicateOtherPlaylist(playlistId: ObjectId, track: Track) {
    let t: Track = JSON.parse(JSON.stringify(track));
    t.id = (new UUID()) + "";
    let playlist = (await this.playlistService.getPlaylist(playlistId)).playlist;
    playlist?.tracks.push(t);
    if (playlist != null) {
      await this.playlistService.updatePlaylist(playlist);
      await this.loadPlaylists();
    }
  }


  selectPlaylist(playlistId: ObjectId): void {
    const index = this.selectedToDuplicate.indexOf(playlistId)
    if (index > -1) {
      this.selectedToDuplicate.splice(index, 1)
    } else {
      this.selectedToDuplicate.push(playlistId)
    }
  }

  duplicateTrackToSelected(track: Track) {
    this.selectedToDuplicate.forEach(async pid => {
      await this.duplicateOtherPlaylist(pid, track)
    })
    this.selectedToDuplicate = [];
  }


  popperShow(event: NgxPopperjsDirective) {
    setTimeout(() => {
      event.hide();
    }, 1000);
  }

}