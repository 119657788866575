<div class="fakebody row p-0 m-0 align-items-center">
  <div class="col p-0 m-0">
    <div class="box m-auto">
      <form (submit)="login()">
        <h2
          class="text-center"
          i18n="@@login"
        >Login</h2>

        <p
          *ngIf="this.message"
          class="alert alert-info"
        >
          {{this.message}}
        </p>

        <div class="inputBox">
          <input
            required="required"
            autocomplete="email"
            type="email"
            id="email"
            name="email"
            [(ngModel)]="email"
          >
          <span i18n="@@emailAddress">EMail Address</span>
          <i></i>
        </div>
        <div class="inputBox">
          <input
            required="required"
            type="password"
            id="password"
            name="password"
            [(ngModel)]="password"
          >
          <span i18n="@@password">Password</span>
          <i></i>
        </div>

        <div class="links">
          <a
            [routerLink]="['/', 'forgot-password']"
            i18n="@@passwordForgotten"
          >Password forgotten?</a>
        </div>

        <div class="text-center mt-3">
          <input
            type="submit"
            class="btn btn-outline-primary"
            style="width: 100px;"
            i18n-value="@@login"
            value="Login"
          >
          <div>
            <span
              i18n="@@new"
              class="me-1"
            >New?</span>
            <a
              href="#"
              [routerLink]="['/','register']"
              i18n="@@signup"
            >Signup</a>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>