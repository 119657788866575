import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsgStatus } from '@reflact/interactionsuite';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  public email?: string;
  public password?: string;
  public message = '';

  constructor(private loginService: LoginService, private router: Router, private aRoute: ActivatedRoute) { }

  public async login() {
    if (this.email && this.password) {
      const result = await this.loginService.login(this.email, this.password);
      if (result.status === 'ok') {
        const requestedView = this.aRoute.snapshot.queryParams['linkTo'];
        if (requestedView != undefined && requestedView != "") {
          this.router.navigate(['/', requestedView]);
        } else {
          this.router.navigate(['/', 'dashboard']);
        }
      } else {
        this.setMessage(result.status);
      }
    }
  }

  private setMessage(status: MsgStatus) {
    switch (status) {
      case 'ok':
        break;
      case 'forbidden':
        this.message = 'Wrong login'; $localize`:@@loginWrong:Wrong login`;
        break;
      case 'error':
        break;
      case 'invalidrequest':
        break;
      default:
        break;
    }
  }
}