<div
  class="d-flex align-items-center"
  [ngClass]="{'text-muted': disabled}"
>
  <div
    class="btn border-0 text-primary"
    (click)="dec()"
    [ngClass]="{'disabled': value <= 1 || disabled}"
  ><i class="ri-indeterminate-circle-fill ri-2x"></i></div>
  <b class="fs-4">
    <input
      [disabled]="disabled"
      [ngClass]="{'text-muted': disabled}"
      type="number"
      class="form-control-flush text-center"
      style="width: 20px;"
      [ngStyle]="{'width': ((this.value.toString().length + 1) * 11) + 'px'}"
      [(ngModel)]="value"
      (change)="validate()"
      min="1"
      required="true"
    >
  </b>
  <div
    class="btn border-0 text-primary"
    (click)="inc()"
    [ngClass]="{'disabled':disabled}"
  ><i class="ri-add-circle-fill ri-2x"></i></div><b>{{unit}}</b>
</div>