<div
  class="row align-items-center justify-content-center"
  style="height: 100vh;"
>
  <div class="col"></div>
  <div class="col-auto text-center">
    <h2
      class="text-primary"
      i18n="@@pausedScenario"
    >Paused</h2>
    <div class="mt-4">
      <span i18n="@@pausedScenarioDescribtion">Please wait while your scenario will continue soon</span>
    </div>
  </div>
  <div class="col"></div>
</div>