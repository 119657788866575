import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlayState, Playlist, Track, TriggerAttandanceControlData } from '@reflact/interactionsuite';
import { waitSeconds } from '@reflact/tsutil';
import { ObjectID } from 'bson';
import * as dayjs from 'dayjs';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { UserIdentifierModalComponent } from 'src/app/shared/components/user-identifier-modal/user-identifier-modal.component';
import { AttendanceObject, AttendanceService } from 'src/app/shared/services/attendance.service';
import { IasSocket, SocketService } from 'src/app/shared/services/socket.service';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent {
  @Input() viewMode: "admin" | "viewer" = "viewer";
  @ViewChild('attendanceBtn') private attendanceBtn?: ElementRef<HTMLDivElement>;
  public playlistId!: string;
  public currentTrack?: Track;
  public socket?: IasSocket;
  public currentAttendanceObject?: AttendanceObject = undefined;
  public timerUntil?: number = undefined;
  public remainingTime?: string = undefined;
  public showIsConfidential = true;
  private userIdentifier?: string = undefined;
  private playlistInfo?: Pick<Playlist, 'collectUserIdentifier'>;
  private paused: boolean = false;
  public timeProgressPercentage?: number;

  constructor(private aRoute: ActivatedRoute, private socketService: SocketService, private attendanceService: AttendanceService, private modalService: BsModalService, private router: Router) {
    try {
      const userIdentifierLocalStorage = localStorage.getItem('interactionUserIdentifier');
      if (userIdentifierLocalStorage && userIdentifierLocalStorage != '') {
        this.userIdentifier = userIdentifierLocalStorage;
      }
    } catch (e) {
    }

    aRoute.params.subscribe(params => {
      this.playlistId = params['playlistId'];
    });

    aRoute.queryParams.subscribe(queryParams => {
      if (queryParams['userIdentifier'] != null) {
        const userIdentifier = (queryParams['userIdentifier'] + "").trim();
        if (userIdentifier != '') {
          this.router.navigate(
            [],
            {
              relativeTo: this.aRoute,
              queryParams: { userIdentifier: undefined },
              queryParamsHandling: 'merge',
              replaceUrl: true
            });
          this.setUserIdentifer(userIdentifier);
        }

      }
    });

    aRoute.data.subscribe(async data => {
      this.playlistInfo = data['playlistInfo'];
      if (this.playlistInfo && this.playlistInfo.collectUserIdentifier && !this.userIdentifier) {
        const userIdentifier = await this.openUserIdentifierModal();
        this.setUserIdentifer(userIdentifier);
        this.initSocket();
      } else {
        this.initSocket();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.socket) {
      this.socket.emit('leaveViewer', { playlistId: this.playlistId }, (result) => {
        console.log('leaveViewer Result', result)
      });
    }
  }

  public async initSocket() {
    this.socket = await this.socketService.socket;
    await this.joinAsViewer();
    this.socket.on('updatePlaystate', (playState: PlayState<string>) => {
      this.currentTrack = playState.currentTrack;
      this.showIsConfidential = true;
      if (playState.status === 'paused') {
        this.paused = true;
        this.currentTrack = this.getPauseTrack();
      } else {
        this.paused = false;
      }

      if (playState.timerUntil != null && this.currentTrack.showTimer) {
        this.timerUntil = playState.timerUntil;
        this.calcRemainingTime();
      } else {
        this.timerUntil = undefined;
        this.remainingTime = undefined;
      }
    });

    this.socket.on('triggerAttendanceControl', (data: TriggerAttandanceControlData) => {
      console.log('attendance Incoming', data);
      this.currentAttendanceObject = {
        id: new ObjectID(data.attendanceId),
        until: data.actionUntilTimestamp,
        token: data.submitToken
      }
      this.activateAttendanceBtn();
      // TODO: test
      const timerUpInMS = this.getTimerUpInMS(data.actionUntilTimestamp);
      setTimeout(this.deactivateAttendanceBtn.bind(this), timerUpInMS)
    });

    SocketService.registered.subscribe((registered: boolean) => {
      if (registered) {
        this.joinAsViewer()
      }
    });
  }

  public getTimerUpInMS(untilTimestamp: number): number {
    const currentDate = new Date()
    return untilTimestamp - currentDate.getTime();
  }

  private getRandomPercentage() {
    // Random Percentage between 10 and 90
    return Math.floor(Math.random() * (90 - 10) + 10);
  }

  private activateAttendanceBtn() {
    if (this.attendanceBtn != null) {
      this.attendanceBtn.nativeElement.style.display = 'block';
      this.attendanceBtn.nativeElement.style.top = this.getRandomPercentage() + '%';
      this.attendanceBtn.nativeElement.style.left = this.getRandomPercentage() + '%';
    }
  }

  private deactivateAttendanceBtn() {
    if (this.attendanceBtn != null) {
      this.attendanceBtn.nativeElement.style.display = 'none';
    }
    this.currentAttendanceObject = undefined;
  }

  public async submitAttendance() {
    if (this.currentAttendanceObject) {
      await this.attendanceService.submitAttendance(this.currentAttendanceObject);
    }
    this.deactivateAttendanceBtn();
  }

  private async joinAsViewer() {
    if (this.socket == null) {
      return;
    }
    this.socket.emit('joinViewer', { playlistId: this.playlistId, userIdentifier: this.userIdentifier }, (result) => { });
  }

  public async calcRemainingTime() {
    if (this.paused) {
      return;
    }

    if (this.timerUntil == null) {
      this.remainingTime = undefined;
    }
    const until = dayjs(this.timerUntil)
    const diff = until.diff(dayjs()) / 1000;

    let minutes = Math.floor(diff / 60)

    if (minutes < 0) {
      minutes = 0;
    }

    let seconds = Math.floor(diff - (minutes * 60));

    if (seconds < 0) {
      seconds = 0;
    }
    if (this.currentTrack) {
      this.timeProgressPercentage = (diff / this.currentTrack.timerSeconds) * 100
    }
    this.remainingTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    if (diff > 0) {
      await waitSeconds(1);
      this.calcRemainingTime();
    }
  }

  private setUserIdentifer(userIdentifier: string) {
    this.userIdentifier = userIdentifier;
    try {
      localStorage.setItem('interactionUserIdentifier', userIdentifier);
    } catch (e) { }
  }

  private async openUserIdentifierModal(): Promise<string> {
    return new Promise((resolve, reject) => {
      const options: ModalOptions = {
        ignoreBackdropClick: true,
        keyboard: false,
      }
      const modalRef = this.modalService.show(UserIdentifierModalComponent, options)
      modalRef.onHide?.subscribe(hide => {
        const userIdentifier = modalRef.content?.userIdentifier;
        console.log('userIdentifier', userIdentifier);
        if (userIdentifier) {
          resolve(userIdentifier)
        } else {
          reject();
        }
      });
    });
  }

  private getPauseTrack(): Track {
    const pauseTrack: Track = {
      id: 'pauseTrack',
      type: 'website',
      data: {
        url: window.location.origin + window.location.origin.match("localhost") ? '/#/pausedScenario' : '/app/en/#/pausedScenario',
        name: 'Paused Scenario',
        linkinfo: undefined
      },
      timerEnabled: false,
      timerSeconds: 0,
      showTimer: false,
      timerAutoNav: false,
      ignoreAttendanceControl: false,
      isConfidential: false,
      confidentialText: ''
    }
    return pauseTrack;
  }

  public hideIsConfidential() {
    this.showIsConfidential = false;
  }
}