<div class="modal-header">
  <h1 i18n="@@aiScenarioHeader">Your AI generated scenario</h1>
</div>
<div class="modal-body">
  <div class="mb-3">
    <label
      for="theme"
      class="form-label"
      i18n="@@aiScenarioTheme"
    >Theme</label>

    <input
      type="text"
      id="theme"
      name="theme"
      class="form-control"
      [(ngModel)]="this.theme"
    >
  </div>

  <div class="mb-3">
    <label
      for="theme"
      class="form-label"
      i18n="@@aiScenarioInteractionCount"
    >Interactions</label>

    <input
      type="number"
      min="1"
      step="1"
      id="interactionCount"
      name="interactionCount"
      class="form-control"
      [(ngModel)]="this.interactionCount"
    >
  </div>

  <div>
    <ol class="list-group list-group-numbered">
      <li
        *ngFor="let item of this.preview"
        class="list-group-item"
      >
        {{item.description}}<br>
        {{item.url}}
      </li>
    </ol>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-secondary"
    i18n="@@close"
  >Close</button>
  <button
    type="button"
    class="btn btn-outline-primary"
    (click)="this.onPreview()"
    i18n="@@preview"
  >Preview</button>
</div>