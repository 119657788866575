<div
  cdkDrag
  class="draggableBar"
>
  <div class="row d-flex justify-content-center">
    <div class="col-auto dragPoint d-flex justify-content-center">
      <i class="ri-drag-move-2-line ri-xl"></i>
    </div>
    <div class="col-auto">
      <div
        class="btn btn-primary"
        (click)="this.reloadPage()"
      >
        <i class="ri-refresh-line"></i>
      </div>
    </div>
  </div>
</div>

<iframe
  *ngIf="this.frameSrc"
  allow="display-capture;camera;microphone;geolocation; fullscreen;accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope; picture-in-picture"
  [src]="this.frameSrc"
  style="height: 100%; width: 100%; border: 0px;"
  class="p-0 m-0"
  name="interaction-suite-frame"
></iframe>