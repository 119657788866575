import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Playlist, Track } from '@reflact/interactionsuite';
import { PlaylistService } from '../../services/playlist.service';
@Component({
  selector: 'app-playlist-editor',
  templateUrl: './playlist-editor.component.html',
  styleUrls: ['./playlist-editor.component.scss']
})
export class PlaylistEditorComponent {
  @Input({ required: true }) public playlist!: Playlist;
  @Output() public deleted: EventEmitter<Playlist> = new EventEmitter<Playlist>();
  @Output() public moveTrack: EventEmitter<Track> = new EventEmitter<Track>();
  @Output() public trackAdded: EventEmitter<Playlist> = new EventEmitter<Playlist>();
  constructor(public playlistService: PlaylistService) { }

  public async updateName(name: string) {
    if (this.playlist) {
      await this.playlistService.updatePlaylist({ _id: this.playlist._id, name })
    }
  }

  playlistHasTracks() {
    if (!this.playlist) return false;
    return this.playlist.tracks.length > 0;
  }
}
