<div
  class="row"
  *ngIf="this.playlist"
>
  <div
    class="col-6 pt-2"
    style="max-height: 380px;overflow-y: scroll;overflow-x: hidden;"
  >
    <app-tracks-legend
      class="h-100 d-block"
      #legend
      [playlist]="playlist"
      [(selectedTrack)]="selectedTrack"
      (moveTrack)="moveTrack.emit($event)"
      mode="edit"
    ></app-tracks-legend>
  </div>
  <div
    class="col-6 ps-0"
    style="max-height: 450px;"
  >
    <div
      class="track-edit-card rounded-3 p-4"
      *ngIf="selectedTrack"
    >
      <p i18n="@@tracksEditorTimerOptions"><b>Timer Options</b></p>
      <div class="d-flex align-items-center">
        <div class="row align-items-center">
          <div
            class="col-12 col-xl-6"
            i18n="@@tracksEditorActiveTimerWith"
          >
            <label class="switch me-2">
              <input
                type="checkbox"
                [(ngModel)]="selectedTrack.timerEnabled"
                (change)="selectedTrack.timerEnabled == false ? this.disableSubTimerOptions() : ''; this.playlistService.updatePlaylist(playlist)"
              >
              <span class="slider round"></span>
            </label>Activate Timer with
          </div>
          <div class="col-12 col-xl-6">
            <app-time-selector
              [(value)]="selectedTrack.timerSeconds"
              unit="seconds"
              i18n-unit="@@tracksEditorUnit"
              [disabled]="!selectedTrack.timerEnabled"
              (valueChange)="this.playlistService.updatePlaylist(playlist)"
            ></app-time-selector>
          </div>
        </div>


      </div>
      <div
        class="d-flex align-items-center mb-3"
        i18n="@@tracksEditorShowRemainingTime"
      >
        <label class="switch me-2">
          <input
            type="checkbox"
            [(ngModel)]="selectedTrack.showTimer"
            [disabled]="!selectedTrack.timerEnabled"
            (change)="this.playlistService.updatePlaylist(playlist)"
          >
          <span class="slider round"></span>
        </label>Show remaning time to participants
      </div>
      <div
        class="d-flex align-items-center mb-3"
        i18n="@@tracksEditorSwitchNextPageTimeUp"
      >
        <label class="switch me-2">
          <input
            type="checkbox"
            [(ngModel)]="selectedTrack.timerAutoNav"
            [disabled]="!selectedTrack.timerEnabled"
            (change)="this.playlistService.updatePlaylist(playlist)"
          >
          <span class="slider round"></span>
        </label>Switch to next page when time is up
      </div>
      <p i18n="@@tracksEditorAttendanceControl"><b>Attendance Control</b></p>
      <div
        class="d-flex align-items-center mb-3"
        i18n="@@tracksEditorAttendanceControlDeactivated"
      >
        <label class="switch me-2">
          <input
            type="checkbox"
            [(ngModel)]="selectedTrack.ignoreAttendanceControl"
            [disabled]="!playlist.attendanceControlEnabled"
            (change)="this.playlistService.updatePlaylist(playlist)"
          >
          <span class="slider round"></span>
        </label>Attendance control deactivated
      </div>
      <p i18n="@@tracksEditorConfidentialOptions"><b>Confidential options</b></p>
      <div
        class="d-flex align-items-center mb-3"
        i18n="@@tracksEditorConfidentialMessage"
      >
        <label class="switch me-2">
          <input
            type="checkbox"
            [(ngModel)]="selectedTrack.isConfidential"
            (change)="this.playlistService.updatePlaylist(playlist)"
          >
          <span class="slider round"></span>
        </label>Confidential message
      </div>
      <textarea
        class="form-control"
        [(ngModel)]="selectedTrack.confidentialText"
        [disabled]="!selectedTrack.isConfidential"
        style="resize: none;"
        i18n-placeholder="@@tracksEditorConfidentialTextPlaceholders"
        placeholder="Your confidential text..."
        (change)="this.playlistService.updatePlaylist(playlist)"
      >
      </textarea>
    </div>
  </div>
</div>