<div
  *ngIf="this.mode && this.playlist"
  class="h-100"
>
  <div
    class="position-absolute"
    style="margin-top: 0px;margin-left: 11px;width: 3px; background-color: #98A0AA;z-index: 3;"
    [ngStyle]="{'height': maxHeight ? maxHeight+'px' : calculateTracksHeight()+'px'}"
  ></div>
  <div
    [sortablejs]="this.playlist.tracks"
    [sortablejsOptions]="sortableOptions"
  >
    <div
      *ngFor="let track of playlist.tracks; let i = index"
      style="z-index: 300; position: relative;"
    >
      <div
        class="row cursor-pointer"
        [ngStyle]="{'height': calcnum + 'px'}"
      >
        <div
          class="col-auto"
          (click)="this.selectTrack(track)"
        >
          <div
            class="d-block rounded-circle bg-white"
            style="width:25px; height: 25px; background-size: cover; border: 3px solid #98A0AA;"
            [ngStyle]="{'background-image': this.playlistService.getFaviconByTrack(track) ? 'url('+this.playlistService.getFaviconByTrack(track)+')':'none'}"
          >
            <div
              *ngIf="!this.playlistService.getFaviconByTrack(track)"
              style="margin-left: 3.5px;"
            >
              <i class="ri ri-html5-fill"></i>
            </div>
          </div>
        </div>
        <div
          [ngClass]="{'col': this.mode == 'edit', 'col-auto': this.mode == 'legend'}"
          style="padding-top: 2px;"
        >
          <div
            class="bg-hover-gray rounded-2 p-2 mt-n2"
            *ngIf="this.selectedTrack == track && editSelected"
          >
            <div class="row">
              <div class="col"><!-- !Das ngModel ist beabsichtigt nicht twoway gebinded!-->
                <input
                  class="form-control form-control-sm"
                  #nameInput
                  placeholder="Edit name here"
                  [ngModel]="track.data.name"
                >
              </div>
              <div class="col-auto">
                <div
                  class="btn btn-sm btn-white"
                  (click)="track.data.name = nameInput.value; this.editSelected = false; this.playlistService.updatePlaylist(playlist)"
                > <i class="ri-checkbox-circle-fill text-success"></i> </div>
              </div>
            </div>
          </div>
          <div
            class="bg-hover-gray rounded-2 p-2 mt-n2"
            *ngIf="!editSelected || this.selectedTrack != track"
            (click)="this.selectTrack(track)"
          >
            {{track.data.name|shorty:20}} <span
              class="d-none d-lg-inline me-1"
              *ngIf="this.mode == 'edit'"
            >({{track.data.url|shorty:24}})</span>
            <i
              class="ri-error-warning-fill ri-lg text-warning"
              [popper]="couldntBeVerified"
              *ngIf="track.data.linkinfo?.info?.iaSuiteEmbedMode != null && track.data.linkinfo?.info?.iaSuiteEmbedMode != 'iframely'"
            ></i>
            <popper-content #couldntBeVerified>
              <span i18n="@@urlCouldNotBeVerified">
                This url could not be verified and must be tested manually!
              </span>
            </popper-content>
          </div>
        </div>
        <div
          class="col-auto ps-0"
          *ngIf="this.mode == 'edit'"
        >
          <div
            class="btn-group"
            container="body"
            dropdown
            placement="bottom left"
          >
            <button
              id="button-basic"
              dropdownToggle
              type="button"
              class="btn btn-white btn-sm"
              aria-controls="dropdown-basic"
              (click)="this.selectTrack(track)"
            >
              <i class="ri-more-2-fill ri-lg fw-bold text-primary"></i>

            </button>
            <ul
              id="dropdown-basic"
              *dropdownMenu
              class="dropdown-menu dropdown-menu-right"
              role="menu"
              aria-labelledby="button-basic"
            >
              <li role="menuitem">
                <div
                  class="dropdown-item text-primary"
                  (click)="this.editSelected = true"
                  i18n="@@tracksLegendEditName"
                >
                  <i class="ri-pencil-fill"></i> Edit name
                </div>
              </li>
              <li role="menuitem">
                <div
                  class="dropdown-item text-primary"
                  (click)="this.duplicateThisFlow(track)"
                  i18n="@@tracksLegendDuplicateThisFlow"
                >
                  <i class="ri-file-copy-fill"></i> Duplicate <small>(this flow)</small>
                </div>
              </li>
              <li role="menuitem">
                <div
                  class="dropdown-item text-primary"
                  (click)="moveTrack.emit(track)"
                  i18n="@@tracksLegendDuplicateOtherScenario"
                >
                  <i class="ri-file-copy-fill"></i> Duplicate <small>(other Scenario)</small>
                </div>
              </li>
              <li role="menuitem">
                <div
                  class="dropdown-item text-danger"
                  (click)="this.editSelected = true; this.removeTrackFromPlayList(i)"
                  i18n="@@tracksLegendRemove "
                >
                  <i class="ri-delete-bin-2-fill"></i> Remove
                </div>
              </li>
            </ul>
          </div>

          <i class="ri-draggable handle cursor-move"></i>
        </div>
        <div
          class="col-2 pe-0"
          *ngIf="this.mode == 'legend'"
        >
          <div
            class="badge bg-primary"
            *ngIf="this.selectedTrack?.id == track.id"
            i18n="@@tracksLegendCurrentlySelected"
          >selected</div>
        </div>
        <div
          class="col-auto pe-0"
          *ngIf="this.mode == 'edit'"
        >
          <div class="triangle-wrapper">

            <div
              class="triangle"
              *ngIf="this.selectedTrack == track"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row me-0 position-relative bg-white white-top-shadow"
    style="z-index: 300;bottom:-1rem;"
    *ngIf="this.mode == 'edit'"
  >
    <div class="col-auto">
      <div
        class="d-block rounded-circle"
        style="width:25px; height: 25px; background-size: cover; border: 3px solid #98A0AA; background-color: #98A0AA; "
      >
        <div style="margin-left: 3.5px;">
          <i class="ri ri-add-fill text-white fw-bold"></i>
        </div>
      </div>
      <div style="width: 20px; background-color: white; height: 25px;"></div>
    </div>
    <div class="col">
      <input
        type="text"
        class="form-control form-control-ias"
        [(ngModel)]="currentUrl"
        [ngClass]="{'loading': this.loading}"
        placeholder="https://www.reflact.com"
      >
      <small i18n="@@tracksLegendHint"><b>Hint:</b> Insert url you want to add to your
        scenario</small>
    </div>
    <div class="col-auto p-0">
      <div
        class="btn btn-white"
        [ngClass]="{'disabled': currentUrl == '' || this.loading}"
        (click)="this.addButtonClicked()"
        i18n="@@tracksLegendAdd"
      > <i class="ri-add-circle-fill"></i> Add</div>
    </div>
  </div>
</div>