<div style="padding: 2rem 1rem;">
  <div class="row">
    <div class="col"></div>
    <div class="col-auto">
      <div class="d-flex align-items-center">
        <ng-container *ngFor="let track of tracks; let i = index">
          <ng-container *ngIf="i < 4">
            <div
              class="d-inline-block rounded-circle bg-white"
              style="width:24px; height: 24px; background-size: cover; border: 3px solid #98A0AA;"
              [ngStyle]="{'background-image': this.playlistService.getFaviconByTrack(track) ? 'url('+this.playlistService.getFaviconByTrack(track)+')':'none'}"
            >
              <div
                *ngIf="!this.playlistService.getFaviconByTrack(track)"
                style="margin-left: 2.5px;"
              >
                <i class="ri ri-html5-fill"></i>
              </div>
            </div>
            <ng-container
              *ngIf="i != (tracks.length-1)"
              [ngTemplateOutlet]="stikeAfter"
            ></ng-container>
          </ng-container>

        </ng-container>
        <ng-container *ngIf="tracks.length == 5">
          <div
            class="d-inline-block rounded-circle bg-white"
            style="width:24px; height: 24px; background-size: cover; border: 3px solid #98A0AA;"
            [ngStyle]="{'background-image': this.playlistService.getFaviconByTrack(tracks[4]) ? 'url('+this.playlistService.getFaviconByTrack(tracks[4])+')':'none'}"
          >
            <div
              *ngIf="!this.playlistService.getFaviconByTrack(tracks[4])"
              style="margin-left: 2.5px;"
            >
              <i class="ri ri-html5-fill"></i>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="tracks.length >= 5 && (tracks.length-5)!= 0">
          <div
            class="d-inline-block rounded-circle text-white"
            style="width:24px; height: 24px; background-color: #98A0AA;"
          >
            <div
              class="fw-bold text-center"
              style="padding-top: 1px;"
            >
              +{{tracks.length - 4}}
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="tracks.length == 0">
          <span
            class="text-white fw-bold"
            style="font-size: 16px;"
            i18n="@@tracksDisplayNoItems"
          >No items here</span>
        </ng-container>
      </div>
    </div>
    <div class="col"></div>
  </div>
</div>

<ng-template #stikeAfter>
  <div
    class="d-inline-block"
    style="height: 3px;background-color: #98A0AA;"
    [ngStyle]="{'width': getStrikeWidth()+'px'}"
  ></div>
</ng-template>