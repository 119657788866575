import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from './shared/shared.module';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SortablejsModule } from '@dustfoundation/ngx-sortablejs';
import { NgxLayoutModule } from '@reflact/ngx-layout';
import { RagDatasupplyModule } from '@reflact/rag-datasupply';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { LogoutGuard } from './shared/guards/logout.guard';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { PlaylistInfoResolver } from './shared/resolver/playlist-info.resolver';
import { SinglePlaylistResolver } from './shared/resolver/single-playlist.resolver';
import { AiModalComponent } from './views/dashboard/ai-modal/ai-modal.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { LoginComponent } from './views/login/login.component';
import { NoScenarioSelectedComponent } from './views/no-scenario-selected/no-scenario-selected.component';
import { PausedScenarioComponent } from './views/paused-scenario/paused-scenario.component';
import { PlayerComponent } from './views/player/player.component';
import { RegisterComponent } from './views/register/register.component';
import { ViewerComponent } from './views/viewer/viewer.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    PlayerComponent,
    ViewerComponent,
    RegisterComponent,
    NoScenarioSelectedComponent,
    ForgotPasswordComponent,
    PausedScenarioComponent,
    AiModalComponent
  ],
  imports: [
    NgxPopperjsModule.forRoot({ placement: NgxPopperjsPlacements.TOP, trigger: NgxPopperjsTriggers.hover }),
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    RagDatasupplyModule,
    SortablejsModule.forRoot({ animation: 150 }),
    ModalModule.forRoot(),
    NgxLayoutModule,
    DragAndDropModule,
    ProgressbarModule.forRoot(),
    ToastrModule.forRoot({ positionClass: 'toast-top-left', preventDuplicates: true })
  ],
  providers: [SinglePlaylistResolver, PlaylistInfoResolver, AuthGuard, LogoutGuard, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
