import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutGuard {
  constructor(private loginService: LoginService, private router: Router) { }
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    if (this.loginService.loggedInUser) {
      this.loginService.logout();
    }
    this.router.navigate(['/', 'login']);
    return false;
  }

}
