import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsgStatus } from '@reflact/interactionsuite';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  public email?: string;
  public message: string = '';

  constructor(private loginService: LoginService, private router: Router) { }

  public async resetPassword() {
    if (this.email) {
      const result = await this.loginService.resetPassword(this.email);
      this.setMessage(result.status);
    }
  }

  private setMessage(status: MsgStatus) {
    switch (status) {
      case 'ok':
        this.message = $localize`:@@forgotPasswordNewPasswortToMail:A new password was send to your e-mail`;
        break;
      case 'forbidden':
        break;
      case 'error':
        break;
      case 'invalidrequest':
        this.message = $localize`:@@forgotPasswordInvalidEMail:Invalid e-mail`;
        break;
      default:
        break;
    }
  }
}