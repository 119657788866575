<div class="modal-header">
  <h4
    class="modal-title pull-left"
    i18n="@@userIdentifierModalEnterUsername"
  >Enter Username</h4>
</div>
<div class="modal-body">
  <input
    class="form-control"
    type="text"
    [(ngModel)]="this.userIdentifier"
  >
</div>
<div class="modal-footer">
  <button
    i18n="@@userIdModalBtn"
    [disabled]="this.userIdentifier == null || this.userIdentifier == ''"
    class="btn btn-primary"
    (click)="submit()"
    i18n="@@userIdentifierModalJoin"
  >Join</button>
</div>