import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Playlist, Track } from '@reflact/interactionsuite';
import { PlaylistService } from 'src/app/shared/services/playlist.service';

@Component({
  selector: 'app-tracks-editor',
  templateUrl: './tracks-editor.component.html',
  styleUrls: ['./tracks-editor.component.scss']
})
export class TracksEditorComponent implements OnInit {

  @Input({ required: true }) public playlist!: Playlist;
  public selectedTrack: Track | undefined = undefined;
  @Output() public moveTrack = new EventEmitter<Track>()

  constructor(public playlistService: PlaylistService) { }

  ngOnInit(): void {
    if (this.playlist.tracks.length != 0) {
      this.selectedTrack = this.playlist.tracks[0];
    }
  }
  public disableSubTimerOptions() {
    if (this.selectedTrack) {
      this.selectedTrack.showTimer = false;
      this.selectedTrack.timerAutoNav = false;
    }
  }
}
