import { Component } from '@angular/core';
import { AiService } from 'src/app/shared/services/ai.service';

type previewEntry = {
  description: string;
  url: string;
}

@Component({
  selector: 'app-ai-modal',
  templateUrl: './ai-modal.component.html',
  styleUrls: ['./ai-modal.component.scss']
})
export class AiModalComponent {
  public theme: string = '';
  public interactionCount: number = 3;
  public preview: previewEntry[] = [];

  constructor(private aiService: AiService) { }

  public async onPreview() {
    const result = await this.aiService.createScenario(this.theme, this.interactionCount);
    this.preview = result;
  }
}
