<div class="fakebody row p-0 m-0 align-items-center">
  <div class="col p-0 m-0">
    <div class="box box-large m-auto">
      <form (submit)="register()">
        <h2
          class="text-center"
          i18n="@@signUp"
        >Sign up</h2>

        <div
          *ngIf="this.message"
          class="alert alert-info"
        >
          {{this.message}}
        </div>

        <input
          required="required"
          type="hidden"
          id="language"
          name="language"
          [(ngModel)]="language"
        >

        <div class="inputBox">
          <input
            required="required"
            autocomplete="name"
            type="text"
            id="name"
            name="name"
            [(ngModel)]="name"
          >
          <span i18n="@@name">Name</span>
          <i></i>
        </div>

        <div class="inputBox">
          <input
            required="required"
            autocomplete="email"
            type="email"
            id="email"
            name="email"
            [(ngModel)]="email"
          >
          <span i18n="@@emailAddress">EMail Address</span>
          <i></i>
        </div>

        <div class="inputBox">
          <input
            autocomplete="new-password"
            required="required"
            type="password"
            id="password"
            name="password"
            [(ngModel)]="password"
          >
          <span i18n="@@password">Password</span>
          <i></i>
        </div>

        <div class="inputBox">
          <input
            autocomplete="new-password"
            required="required"
            type="password"
            id="password2"
            name="password2"
            [(ngModel)]="password2"
          >
          <span i18n="@@repeatPassword">Repeat Password</span>
          <i></i>
        </div>

        <div class="links">
          <a
            [routerLink]="['/', 'login']"
            i18n="@@backToLogin"
          >Back to login</a>
        </div>

        <div class="text-center mt-3">
          <input
            type="submit"
            class="btn btn-outline-primary"
            style="width: 100px;"
            i18n-value="@@signUp"
            value="Sign up"
          >
        </div>
      </form>
    </div>
  </div>

</div>