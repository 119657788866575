import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root'
})
export class ShareTokenOrAuthGuard {
  constructor(private loginService: LoginService) { }
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    const shareToken: string = route.queryParams['shareToken'];
    if (shareToken != null) {
      const shareTokenResult = await this.loginService.isShareTokenValid(shareToken);
      if (shareTokenResult.status === 'ok') {
        this.loginService.shareToken = shareToken;
      }
    }
    if (this.loginService.loggedInUser) {
      return true;
    }
    const loginResult = await this.loginService.getTokenAndLogin();
    if (loginResult.status === 'ok') {
      return true;
    }
    if (this.loginService.shareToken != null) {
      return true;
    }
    return false;
  }

}
