import { Injectable } from '@angular/core';
import { Track } from '@reflact/interactionsuite';
import { ObjectId } from 'bson';
import { SocketService } from './socket.service';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  constructor(private socketService: SocketService) { }
  public async playTrack(playListId: string | ObjectId, track: Track, status: 'playing' | 'paused') {
    playListId = playListId + "";
    const socket = await this.socketService.socket;
    socket.emit('updatePlaystate', { playlistId: playListId, currentTrack: track, status: status }, (result) => {
      console.log('updated Playstate', result)
    });
  }
}
