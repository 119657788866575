<div
  class="row align-items-center justify-content-center"
  style="height: 100vh;"
>
  <div class="col"></div>
  <div class="col-auto text-center">
    <h2
      class="text-primary"
      i18n="@@noScenarioSelected"
    >No scenario selected</h2>
    <div class="mt-4">
      <span i18n="@@noScenarioSelectedDescribtion">Please wait while your presenter selects an scenario</span>
    </div>
  </div>
  <div class="col"></div>
</div>