import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-time-selector',
  templateUrl: './time-selector.component.html',
  styleUrls: ['./time-selector.component.scss']
})
export class TimeSelectorComponent {
  @Input() public disabled: boolean = false;
  @Input() public value: number = 1;
  @Input() public unit: string = "minutes";
  @Output() public valueChange = new EventEmitter<number>();

  public dec() {
    this.addMinutes(-1);
  }

  public inc() {
    this.addMinutes(1);
  }

  private addMinutes(num: number) {
    this.value = this.value + num;
    this.valueChange.emit(this.value)
  }

  public validate() {
    if (this.value < 1 || isNaN(this.value)) {
      this.value = 1;
    }
    this.valueChange.emit(this.value)
  }
}
