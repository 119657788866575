import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Playlist } from '@reflact/interactionsuite';
import { ObjectId } from 'bson';
import { Observable, of } from 'rxjs';
import { PlaylistService } from '../services/playlist.service';

@Injectable({
  providedIn: 'root'
})
export class SinglePlaylistResolver  {
  constructor(private playlistService: PlaylistService) { }
  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Playlist | null> {
    if (route.params['playlistId'] == null) {
      return null;
    };
    const playlistId = new ObjectId(route.params['playlistId']);
    const result = await this.playlistService.getPlaylist(playlistId);
    return result.playlist;
  }
}
