<rag-body-layout
  [navItems]="navItems"
  logoUrl="assets/logo_grey_w_text.svg"
  [loggedInUser]="{
    name: this.loginService.loggedInUser?.name!
  }"
  [sidebarMinimized]="selectScenarioMode"
  pageTitle="My Scenarions"
  i18n-pageTitle="@@dashboardMyScenarions"
  [logoutFunction]="this.loginService.getLogoutFunction()"
  (searchEvent)="this.filterScenarios($event)"
>
  <!--No FirstTile for adding new scenario in selectScenarioMode -->
  <lib-rag-datasupply-grid
    [service]="this.frontendService"
    [template]="tileTpl"
    [templateFirstItem]="selectScenarioMode ? undefined: firstTile"
  >
  </lib-rag-datasupply-grid>
</rag-body-layout>

<ng-template #firstTile>
  <div
    class="btn btn-outline-secondary d-flex shadow "
    (click)="this.createPlaylist()"
  >
    <i class="ri-add-line ri-5x align-self-center m-auto"></i>
    <!--<i
      (click)="this.createAiPlaylist($event)"
      class="ri ri-robot-fill ri-2x align-self-end"
    ></i>-->
  </div>
</ng-template>

<ng-template #aiModal></ng-template>

<ng-template
  #tileTpl
  let-data="data"
>
  <app-playlist-tile
    [playlist]="data"
    [selectScenarioMode]="selectScenarioMode"
    (edit)="overlay.setSelected($event)"
    (selected)="emitSelectedPlaylistToParentWindow($event)"
  ></app-playlist-tile>
</ng-template>

<rag-overlay-aside #overlay>
  <div
    header
    *ngIf="overlay.selectedObject"
  >
    <div class="row align-items-center">
      <div class="col">
        <div
          *ngIf="!editName"
          (click)="editName = true; nameInput.classList.remove('d-none'); nameInput.focus(); nameInput.setSelectionRange(0, nameInput.value.length);"
        >
          <h1>
            {{overlay.selectedObject.name}}
            <span
              *ngIf="overlay.selectedObject.name == ''"
              style="font-size: inherit"
              class="ri ri-information-line text-info"
              i18n="@@playlistEmptyName"
            >
              Click here to enter a name
            </span>
          </h1>
        </div>
        <div>
          <input
            #nameInput
            [ngClass]="{'d-none': !editName}"
            style="height: 55px; overflow: visible;"
            class="form-control-flush fs-1 fw-bold"
            [(ngModel)]="overlay.selectedObject.name"
            (blur)="editName = false;"
            (change)="this.playlistService.updatePlaylist(overlay.selectedObject); editName = false;"
          >
        </div>
      </div>
      <div class="col-auto">
        <!--
         <div class="btn btn-white btn-md d-inline-flex align-items-center disabled">
           <i class="ri-share-box-line ri-lg"></i>&nbsp;Share
          </div>
        -->
        <div
          class="btn btn-white btn-md d-inline-flex align-items-center"
          [popper]="copied"
          [popperTrigger]="popperTriggerClick"
          (click)="copyToClipBoard(overlay.selectedObject,'viewer',$event)"
          (popperOnShown)="popperShow($event)"
          i18n="@@playlistTileEmbed"
        ><i class="ri-file-copy-2-fill"></i>&nbsp;Embed</div>
        <div
          class="btn btn-white btn-md d-inline-flex align-items-center"
          [popper]="copied"
          [popperTrigger]="popperTriggerClick"
          (popperOnShown)="popperShow($event)"
          (click)="copyToClipBoard(overlay.selectedObject,'dj', $event)"
          i18n="@@playlistTileAdmin"
        ><i class="ri-file-copy-2-fill"></i>&nbsp;Admin</div>
        <div
          class="btn btn-white btn-md d-inline-flex align-items-center"
          [popper]="copied"
          [popperTrigger]="popperTriggerClick"
          (popperOnShown)="popperShow($event)"
          (click)="copyIframeToClipboard()"
          i18n="@@dashboardIFrame"
        >
          <i class="ri-file-copy-2-fill ri-lg"></i>&nbsp;iFrame
        </div>

        <popper-content #copied>
          <span
            class="text-nowrap"
            i18n="@@playlistTileCopiedToClipboard"
          >
            copied to clipboard
          </span>
        </popper-content>
      </div>
    </div>
  </div>
  <div *ngIf="overlay.selectedObject">
    <app-playlist-editor
      [playlist]="overlay.selectedObject"
      (trackAdded)="onTrackAdded($event)"
      (deleted)="overlay.openModal(deleteModal)"
      (moveTrack)="this.trackToMove = $event; overlay.openModal(duplicationModal)"
    ></app-playlist-editor>
    <ng-template #duplicationModal>
      <div class="text-center">

        <h3 i18n="@@dashboardDublicatinHeadline">Duplicate object into another scenario</h3>
        <p
          class="text-primary"
          i18n="@@dashboardDuplicateInformations"
        >Drag the selected object to one of your scenarios to create a duplicate there.
          create a duplicate there.</p>
        <div *ngIf="trackToMove">
          <div class="text-center mb-3">
            <div
              class="d-inline-block p-3 draggable"
              mwlDraggable
              [dropData]="trackToMove"
              dragActiveClass="drag-active"
              [touchStartLongPress]="{ delay: 300, delta: 30 }"
            >
              <div class="d-flex align-items-center">
                <div
                  class="d-inline-block"
                  style="height:36px; width: 36px; background-size: cover; border: 3px solid #98A0AA; border-radius: 50%;"
                  [ngStyle]="{'background-image': this.playlistService.getFaviconByTrack(trackToMove) ? 'url('+this.playlistService.getFaviconByTrack(trackToMove)+')':'none'}"
                >
                  <div
                    *ngIf="!this.playlistService.getFaviconByTrack(trackToMove)"
                    style="margin-top: -3px;"
                  >
                    <i class="ri ri-html5-fill ri-2x  text-muted"></i>
                  </div>
                </div>
                <span class="d-inline-block fs-5 mx-3">{{trackToMove.data.name}}</span>
                <i class="ri-drag-move-2-fill ri-2x"></i>
              </div>
            </div>
          </div>
          <div>
            <hr>
            <div style="height: 40vh; overflow: scroll">
              <div class="row m-0">

                <div
                  class="col-3 mb-4"
                  *ngFor="let playlist of playlists"
                >
                  <div
                    class="bg-secondary rounded-3 p-2 cursor-pointer"
                    [ngClass]="{'selected': this.selectedToDuplicate.includes(playlist._id),}"
                    (click)="selectPlaylist(playlist._id)"
                    style="height: 100%;"
                    mwlDroppable
                    (drop)="selectPlaylist(playlist._id)"
                    dragOverClass="drop-over-active"
                  >
                    <div class=" p-2 fw-bold d-flex align-items-center justify-content-center py-5">
                      {{playlist.name}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center mt-2">
            <div
              class="btn btn-sm btn-white text-primary"
              (click)="overlay.closeModal(); this.selectedToDuplicate = []"
              i18n="@@cancel"
            ><i class="ri-close-line"></i> Cancel</div>
            <div
              class="btn btn-sm btn-primary text-white"
              [ngClass]="{'disabled': this.selectedToDuplicate.length == 0}"
              (click)="overlay.closeModal(); this.duplicateTrackToSelected(trackToMove)"
              i18n="@@duplicateToSelected"
            >Duplicate to selected</div>
          </div>
        </div>


      </div>
    </ng-template>
    <ng-template #deleteModal>
      <h3
        class="text-danger text-center"
        i18n="@@dashboardAttention"
      >Attention</h3>
      <p
        class="text-center fs-5"
        i18n="@@dashboardDeleteQuestion"
      >Are you sure to delete the following scenario:</p>
      <p
        class="text-center fw-bold text-primary fs-4"
        *ngIf="overlay.selectedObject"
      >{{overlay.selectedObject.name}}</p>

      <div class="row">
        <div class="col text-end">
          <div
            class="btn btn-lg btn-danger text-white"
            (click)="overlay.closeModal();deletePlaylist(overlay.selectedObject); overlay.unsetSelected(); this.selectedToDuplicate = []"
            i18n="@@delete"
          ><i class="ri-delete-bin-fill"></i> Delete</div>
        </div>
        <div class="col">
          <div
            class="btn btn-lg btn-outline-primary border-0"
            (click)="overlay.closeModal(); this.selectedToDuplicate = []"
            i18n="@@cancel"
          ><i class="ri-close-line"></i> Cancel</div>
        </div>
      </div>
    </ng-template>
  </div>
</rag-overlay-aside>