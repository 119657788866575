import { Component, Input } from '@angular/core';
import { Track } from '@reflact/interactionsuite';
import { PlaylistService } from 'src/app/shared/services/playlist.service';

@Component({
  selector: 'app-tracks-display',
  templateUrl: './tracks-display.component.html',
  styleUrls: ['./tracks-display.component.scss']
})
export class TracksDisplayComponent {
  @Input() public tracks: Track[] = [];
  constructor(public playlistService: PlaylistService) { }

  public getStrikeWidth() {
    const displayedItems = Math.min(this.tracks.length, 6)
    return 100 / displayedItems;
  }
}
