<div
  class="text-secondary"
  *ngIf="this.playlist"
>
  <p i18n="@@playlistEditorAttendanceControlOptions"><b>Attendance control options</b></p>
  <div class="bg-white rounded-4 shadow p-4 d-flex align-items-center mb-4">
    <div class="row">
      <div
        class="col-auto d-flex align-items-center"
        i18n="@@playlistEditorRequireUsername"
      >
        <label class="switch me-2">
          <input
            type="checkbox"
            [(ngModel)]="playlist.collectUserIdentifier"
            (change)="playlist.collectUserIdentifier == false ? playlist.attendanceControlEnabled = false : ''; this.playlistService.updatePlaylist(playlist)"
          >
          <span class="slider round"></span>
        </label><b>Require Username</b>
      </div>
      <div
        class="col-auto d-flex align-items-center"
        [ngClass]="{'text-muted': !playlist.collectUserIdentifier}"
        i18n="@@playlistEditorAttendanceControl"
      >
        <label class="switch me-2">
          <input
            type="checkbox"
            [disabled]="!playlist.collectUserIdentifier"
            [(ngModel)]="playlist.attendanceControlEnabled"
            (change)="this.playlistService.updatePlaylist(playlist)"
          >
          <span class="slider round"></span>
        </label><b>Attendance Control</b>
      </div>
      <div class="col-auto d-flex align-items-center">
        <app-time-selector
          [(value)]="playlist.attendanceControlMinutes"
          [disabled]="!playlist.attendanceControlEnabled"
          (valueChange)="this.playlistService.updatePlaylist(playlist)"
        ></app-time-selector>
      </div>
      <div
        class="col d-flex align-items-center"
        i18n="@@playlistEditorExportAttendances"
      >
        <div
          class="btn btn-white btn-md d-inline-flex align-items-center"
          *ngIf="playlist.collectUserIdentifier && playlist.attendanceControlEnabled"
          (click)="playlistService.attendanceExport(playlist._id)"
        >
          <i class="ri-download-line ri-lg"></i>&nbsp;Export attendances
        </div>
      </div>
    </div>
  </div>
  <p><b i18n="@@playlistEditorEditFlow">Edit flow</b><br>
    <span class="text-primary">
      <i class="spf-hand-fill ri-lg me-1"></i>
      <b i18n="@@playlistEditorEditFlowDescribtion">Click on the existing websites to edit them or move them via drag &
        drop</b>
    </span>
  </p>
  <div class="bg-white rounded-4 shadow p-4">
    <app-tracks-editor
      [playlist]="playlist"
      (moveTrack)="moveTrack.emit($event)"
    ></app-tracks-editor>
  </div>


</div>
<div
  class="btn btn-white mt-3"
  (click)="this.deleted.emit(playlist)"
>
  <span
    class="text-danger"
    i18n="@@delete"
  ><i class="ri-delete-bin-fill"></i> Delete</span>
</div>