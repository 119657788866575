import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { LogoutGuard } from './shared/guards/logout.guard';
import { ShareTokenOrAuthGuard } from './shared/guards/share-token-or-auth.guard';
import { PlaylistInfoResolver } from './shared/resolver/playlist-info.resolver';
import { SinglePlaylistResolver } from './shared/resolver/single-playlist.resolver';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { LoginComponent } from './views/login/login.component';
import { NoScenarioSelectedComponent } from './views/no-scenario-selected/no-scenario-selected.component';
import { PausedScenarioComponent } from './views/paused-scenario/paused-scenario.component';
import { PlayerComponent } from './views/player/player.component';
import { RegisterComponent } from './views/register/register.component';
import { ViewerComponent } from './views/viewer/viewer.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "selectScenario",
    pathMatch: "full",
    canActivate: [AuthGuard],
    component: DashboardComponent,
    data: {
      selectScenario: true
    }
  },
  {
    path: "player/:playlistId",
    pathMatch: "full",
    component: PlayerComponent,
    canActivate: [ShareTokenOrAuthGuard],
    resolve: {
      playlist: SinglePlaylistResolver
    }
  },
  {
    path: "viewer/:playlistId",
    pathMatch: "full",
    component: ViewerComponent,
    resolve: {
      playlistInfo: PlaylistInfoResolver
    }
  },
  {
    path: "login",
    pathMatch: "full",
    component: LoginComponent
  },
  {
    path: "register",
    pathMatch: "full",
    component: RegisterComponent
  },
  {
    path: "forgot-password",
    pathMatch: "full",
    component: ForgotPasswordComponent
  },
  {
    path: "noScenario",
    pathMatch: "full",
    component: NoScenarioSelectedComponent
  },
  {
    path: "pausedScenario",
    pathMatch: "full",
    component: PausedScenarioComponent
  },
  {
    path: "logout",
    pathMatch: "full",
    canActivate: [LogoutGuard],
    // empty children are needed, because canActivate has an issue otherwise
    // https://github.com/angular/angular/issues/18605
    children: []
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
