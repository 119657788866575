import { AfterViewChecked, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Playlist, Track } from '@reflact/interactionsuite';
import { NgxPopperjsDirective, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { LoginService } from 'src/app/shared/services/login.service';
import { PlaylistService } from 'src/app/shared/services/playlist.service';
import { IasSocket, SocketService } from 'src/app/shared/services/socket.service';

@Component({
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnDestroy, AfterViewChecked {
  public playlist: Playlist;
  public playlistStatus: Playlist['playState']['status'] = 'playing';
  public currentTrack?: Track;
  public socket?: IasSocket;
  public selectedTrack?: Track;
  public openControls: boolean = true
  public popperTriggerClick = NgxPopperjsTriggers.click;
  public popperPlacement = NgxPopperjsPlacements.BOTTOMSTART;
  public maxHeightForLegend: number = 563;
  constructor(private aRoute: ActivatedRoute, private socketService: SocketService, public loginService: LoginService, private playlistService: PlaylistService) {
    const snapshot = aRoute.snapshot;
    const data: Data = snapshot.data;
    this.playlist = data['playlist'];
    this.playlistStatus = this.playlist.playState.status;
    this.initSocket()
  }


  ngAfterViewChecked(): void {
    this.calculateMaxHeightForLegend()
  }

  public calculateMaxHeightForLegend() {
    let box = document.getElementById("legendBox");
    if (box) {
      let width = box.offsetWidth;
      console.log("🚀 ~ file: player.component.ts:33 ~ PlayerComponent ~ getMaxHeightForLegend ~ width:", width)
      let height = box.offsetHeight;
      console.log("🚀 ~ file: player.component.ts:34 ~ PlayerComponent ~ getMaxHeightForLegend ~ height:", height)
      this.maxHeightForLegend = height - 2
    } else {
      this.maxHeightForLegend = 100;
    }
  }

  public async initSocket() {
    this.socket = await this.socketService.socket;
    await this.joinAsDJ();
    this.socket.on('updatePlaystate', (playState) => {
      console.log('RECIVE', playState);
      if (playState.playlistId === this.playlist._id + "") {
        this.currentTrack = playState.currentTrack;
        this.selectedTrack = this.currentTrack;
      }
      this.playlistStatus = playState.status;
    });
    SocketService.registered.subscribe((registered: boolean) => {
      if (registered) {
        this.joinAsDJ()
      }
    });
  }
  public emitSwitchScenario() {
    if (window.parent) {
      window.parent.postMessage({ unset: true }, '*');
    }
  }
  ngOnDestroy(): void {
    if (this.socket) {
      this.socket.emit('leaveDJ', { playlistId: this.playlist._id + "" }, (result) => {
        console.log('leaveDJ Result', result)
      });
    }
  }
  private async joinAsDJ() {
    if (this.socket == null) {
      return;
    }
    this.socket.emit('joinDJ', { playlistId: this.playlist._id + "" }, (result) => {
      console.log('joinDJ Result', result)
    });
  }

  copyToClipBoard(type: "viewer" | "dj", event: MouseEvent) {

    if (!this.playlist) return;
    let clipBoardMsg = "";
    if (type == "viewer") {
      clipBoardMsg = this.playlistService.getViewerUrl(this.playlist._id)
    }
    if (type == "dj") {
      clipBoardMsg = this.playlistService.getDjUrl(this.playlist._id)
    }
    if (event.metaKey) {
      window.open(clipBoardMsg, '_blank');
      return;
    }
    this.copyMessage(clipBoardMsg);
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  popperShow(event: NgxPopperjsDirective) {
    setTimeout(() => {
      event.hide();
    }, 1000);
  }
}
