<div class="fakebody row p-0 m-0 align-items-center">
  <div class="col p-0 m-0">
    <div class="box m-auto">
      <form (submit)="this.resetPassword()">
        <h2
          class="text-center"
          i18n="@@forgotPassword"
        >Forgot password</h2>

        <p
          *ngIf="this.message"
          class="alert alert-info"
        >
          {{this.message}}
        </p>

        <div class="inputBox">
          <input
            required="required"
            autocomplete="email"
            type="email"
            id="email"
            name="email"
            [(ngModel)]="email"
          >
          <span i18n="@@emailAddress">EMail Address</span>
          <i></i>
        </div>

        <div class="links">
          <a
            [routerLink]="['/', 'login']"
            i18n="@@backToLogin"
          >Back to login</a>
        </div>

        <div class="text-center mt-3">
          <input
            type="submit"
            class="btn btn-outline-primary"
            style="width: 120px;"
            i18n-value="@@resetPassword"
            value="Reset password"
          >
        </div>
      </form>
    </div>
  </div>
</div>