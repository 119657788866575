import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-user-identifier-modal',
  templateUrl: './user-identifier-modal.component.html',
  styleUrls: ['./user-identifier-modal.component.scss']
})
export class UserIdentifierModalComponent {
  public userIdentifier?: string;

  constructor(public bsModalRef: BsModalRef) { }

  public submit() {
    if (this.userIdentifier != null && this.userIdentifier != '') {
      this.bsModalRef.hide();
    }
  }
}
